<template>
  <div class="course_progress_container">
    <div class="course_progress_item">
      <div v-if="calcCourseProgress == 100" class="course_progress_icon_container">
        <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 1L4.75 9L1 5.36364" stroke="#16171A" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <ProgressCircular id="curso" v-else :progress="calcCourseProgress" />
      <div style="margin-left: 20px;" class="course_progress_content">
        <span class="course_progress_title"
        >
          {{ $t("general.my_progress") }} - {{ calcCourseProgress }}%
        </span>
        <span class="course_progress_subtitle">
          {{ getCurrentCourse.lessons_completed_count }} {{ $t("player.of") }} {{ getCurrentCourse.lessons_count }} {{ $t("player.class") }}
        </span>
      </div>
    </div>
    <div class="course_progress_content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ProgressCircular from '@/components/V2/player/ProgressCircular.vue'

export default {
  computed: {
    ...mapGetters([
      'getCurrentCourse',
      'getCurrentModule',
      'getCurrentModules',
      'getCurrentModuleLessons'
    ]),
    calcCourseProgress() {
      return Math.round(this.getCurrentCourse.percentage_complete)
    }
  },
  components: {
    ProgressCircular
  },
  methods: {
    ...mapActions(['goToEspecificModule'])
  },
}
</script>

<style lang="scss">
.course_progress_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.course_progress_item {
  flex-wrap: nowrap;
  padding-right: 20px;
  padding-left: 0px;
  display: flex;
  align-items: center;
  border-radius: 21px;
  width: 100%;
  background: var(--background-v2);
  color: #9A9A9A !important;
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
}
.course_progress_icon_container {
  width: 30px;
  height: 30px;
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--maincolor);
  margin-right: 20px;
}
.course_progress_icon_progress {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--maincolor);
  margin-right: 20px;
}

.course_progress_content {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 768px) {
  .course_progress_content {
    margin-left: 16px;
  }
}
.course_progress_title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: var(--fontcolor-v2)
}
.course_progress_subtitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--fontcolor2-v2)
}
.selected {
  font-weight: 500;
}
</style>