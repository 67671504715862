<template>
  <div class="lesson-list-container">
    <div class="input_container">
      <svg
        class="input_search_icon"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 13L10.1 10.1M11.6667 6.33333C11.6667 9.27885 9.27885 11.6667 6.33333 11.6667C3.38781 11.6667 1 9.27885 1 6.33333C1 3.38781 3.38781 1 6.33333 1C9.27885 1 11.6667 3.38781 11.6667 6.33333Z"
          stroke="#6F6F6F"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <input
        v-model="search"
        type="text"
        class="input_field_search"
        autocomplete="off"
        :placeholder="$t('player.search_for_your_content')"
        name="name"
        id="name"
        v-on:keyup.enter="SearchLesson"
        v-b-tooltip.hover
        title="Pressione a tecla 'Enter' para iniciar a pesquisa."
      />
    </div>
    <div class="max-height-lesson-list">
      <div class="telegram" v-if="showTelegramLink">
        <img
          @click="openTelegramLink"
          height="40"
          width="40"
          src="@/assets/img/telegram.png"
          alt="telegram-logo"
        />
        <div>
          <h6 class="titleModule">Grupo no Telegram</h6>
          <a
            v-if="telegramLink"
            class="moduleLiberation"
            :href="telegramLink"
            target="_blank"
            >Clique aqui para entrar no grupo do telegram</a
          >
          <span v-else class="moduleLiberation">
            Ainda não foi liberado para entrada.
          </span>
        </div>
      </div>
      <CourseProgress v-if="lessons.length <= 0" />
      <div v-if="lessons.length >= 1">
        <LessonListItem 
          @clear-search="onClearSearch"
          :is-search="true"
          v-for="(lesson, index) in lessons"
          :key="lesson.id"
          :lesson="lesson"
          :order="index + 1"
        />
      </div>
      <LessonList
        v-else
        v-for="item in getCurrentModules"
        :key="item.id"
        :is-search="false"
        :module="item"
        :isCurrentModule="item.id === getCurrentModule.id ? true : false"
      >
        <div
          style="display: flex; flex-direction: column; width: 100%"
          v-if="item.id === getCurrentModule.id"
        >
          <LessonListItem
            v-for="(lesson, index) in getCurrentModuleLessons"
            :key="lesson.id"
            :lesson="lesson"
            :order="index + 1"
          />
        </div>
      </LessonList>
    </div>
  </div>
</template>

<script>
import LessonList from "./LessonList/index.vue";
import LessonListItem from "./LessonListItem/index.vue";
import CourseProgress from "./CourseProgress/index.vue";
import { mapGetters } from "vuex";
import notify from "@/services/libs/notificacao";
import TelegramService from '@/services/resources/ClassIntegrationService';
import lessonSearchService from "@/services/resources/LessonSearchService";

const telegramService = TelegramService.build();
const lessonService = lessonSearchService.build();

export default {
  data() {
    return {
      search: '',
      search_value: '',
      lessons: [],
      showTelegramLink: false,
      telegramLink: '',
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentLesson',
      "getCurrentModule",
      "getCurrentModules",
      "getCurrentModuleLessons",
      'getNextModule',
      'getPlayerConfig',
      'getHasNextLesson',
      'getHasNextModule',
    ]),
  },
  methods: {
    onClearSearch() {
      this.lessons = [];
    },
    SearchLesson() {
      this.getLessons(this.search);
    },
    async getLinkTelegram() {
      let idCourse = this.$route.params.curso;
      await telegramService
        .read({ id: `/link-telegram/${idCourse}` })
        .then((resp) => {
          this.telegramLink = resp.link;
          if (resp.link) {
            this.showTelegramLink = true;
          } else if (resp.liberated_at) {
            this.showTelegramLink = true;
          }
        })
        .catch((err) => {
          var error = JSON.parse(err.response.data);
          var msg = '';
          for (var indice in error) {
            msg += error[indice][0] + '<br>';
          }
          if (msg !== '') {
            notify('erro', msg);
          }
        })
        .finally(() => {
          this.$root.$emit('loadOff');
        });
    },
    getLessons(search) {
      let idCourse = this.$route.params.curso;
      if(search == ""){
        this.lessons = [];
        return;
      }
        
        lessonService
        .read(`/${idCourse}/${search}`)
        .then((resp) => {
          this.lessons = resp;
          this.search = "";
        })
        .catch((err) => {
            notify('erro', err);
        })
    },
    openTelegramLink() {
      window.open(this.telegramLink, '_blank');
    },
  },
  mounted() {
    this.getLinkTelegram();
  },
  components: {
    LessonList,
    LessonListItem,
    CourseProgress,
  },
};
</script>

<style lang="scss">
.input_search_icon {
  fill: rgba(0, 0, 0, 0);
  stroke: var(--bordercolor-v2);
  margin-left: 20px;
  margin-top: 13px;
}
.lesson-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.max-height-lesson-list {
  max-height: 70vh;
  overflow: auto;
}
.max-height-lesson-list::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
}

.max-height-lesson-list::-webkit-scrollbar-track {
  background: var(--background2-v2); /* color of the tracking area */
  border-radius: 6px;
}

.max-height-lesson-list::-webkit-scrollbar-thumb {
  background-color: var(--fontcolor2-v2); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
.input_container {
  width: 100%;
  display: flex;
  flex-wrap: no-wrap;
}

.input_container__field {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 50px;
  width: 100%;
  background: #1e2024 !important;
  color: #9a9a9a !important;
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
}

.input_container__field::placeholder {
  color: #9a9a9a;
}

.input_container__button {
  background-color: #3483fa;
  border: none;
  color: #fff;
  padding-left: 20px;
  width: 141px;
  right: 0px;
  position: absolute;
  padding-right: 20px;
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 50px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
}
.input_container {
  width: 100%;
  display: flex;
  background: var(--background2-v2);
  border-radius: 50px;
}
.input_field_search {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 50px;
  width: 100%;
  background-color: var(--background2-v2);
  background: var(--background2-v2);
  color: var(--fontcolor-v2);
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
}
.input_field_search::placeholder {
  color: var(--fontcolor2-v2);
}
@media screen and (min-width: 768px) {
  .input_field_search {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 50px;
    width: 100%;
    background-color: var(--background2-v2);
    background: var(--background2-v2);
    color: var(--fontcolor-v2);
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
  }
}

.telegram {
  text-decoration: none;
  display: flex;
  gap: 10px;
  color: var(--text-color);
  margin-top: 20px;

  .titleModule {
    font-family: 'Montserrat Alternates';
    font-style: normal;
    font-weight: 800;
  }

  .moduleLiberation {
    color: var(--maincolor);
  }
}
</style>
