<template>
  <div @contextmenu="blockContextMenu" style="width: 100%;     height: auto;">
    <iframe class="iframePdf" :src="pdfSrc" ref="pdfViewer" @mouseover="blockHoverEvent"></iframe>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      loading: false,
      pdfSrc: null, // Inicializamos como null
    };
  },
  computed: {
    ...mapGetters(["getCurrentLesson"]),
  },
  methods:{
    blockContextMenu(event) {
      if (
        event.target === this.$el ||
        event.target.tagName === 'IFRAME' ||
        this.$el.contains(event.target)
      ) {
        event.preventDefault();
      }
    },
    blockHoverEvent(event) {
      event.preventDefault();
      event.stopPropagation();
    }
  },  
  mounted() {
    console.log('getCurrentLesson', this.getCurrentLesson.media.id);
    axios({
      url:
        process.env.VUE_APP_API_HOST +
        `/media/${this.getCurrentLesson.media.id}/viewPDF`,
      method: "POST",
      responseType: "arraybuffer",
      headers: {
        Authorization: Cookies.get("auth_greennCourse"),
      },
    }).then((response) => {
      const pdfData = new Uint8Array(response.data);
      const blob = new Blob([pdfData], { type: "application/pdf" });
       var url_blob = URL.createObjectURL(blob); // Atribuímos o URL do Blob à variável pdfSrc

      console.log('pdfSrc', this.pdfSrc);

      var concat_url = window.location.origin + '/pdf-js/web/viewer.html?file=' + url_blob;

      console.log('pdfSrc - concat_url', concat_url);

      this.pdfSrc = concat_url;
    });
  },
  beforeDestroy() {
    if (this.pdfSrc) {
      URL.revokeObjectURL(this.pdfSrc); // Limpa o URL temporário para evitar vazamentos de memória
    }
  },
};
</script>

<style scoped lang="scss">
iframe{
  width: 100% !important;
}
.iframePdf {
  height: 60vh !important;
  width: 100vw !important;
  max-width: 100% !important;
}
</style>