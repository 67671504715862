<template>
  <youtube
    :style="`${maxHeight}${fullScreenClass}`"
    class="yt-iframe"
    :video-id="getId()"
    ref="youtube"
    :resize="true"
    @playing="playing"
    :attrs="{'allowfullscreen': true}"
    allow="fullscreen"
  ></youtube>
</template>

<script>
import notify from "@/services/libs/notificacao";
import { mapGetters } from "vuex";

export default {
  name: "VideoPlayerYT",
  props: ["porcentagem", "source", "isMobile", "apiLesson"],
  computed: {
    ...mapGetters(['getCurrentLesson']),
    player() {
      const player = this.$refs.youtube;
      return player;
    },
    maxHeight() {
      if (this.isFullscreen) {
        return '';
      }
      if (this.isMobile) {
        return 'max-height: 250px; min-height: 250px;'
      } else {
        return 'min-height: 530px; max-height: 530px; width: 100%;'
      }
    },
    fullScreenClass () {
      if (this.isFullscreen) {
        return 'position: fixed; top:0; left:0; right:0; bottom: 0;'
      } else {
        return ''
      }
    }
  },
  data() {
    return {
      isFullscreen: false,
      playerIsStarted: false
    }
  },
  methods: {
    enterFullscreen() {
      if (this.$refs.youtube.requestFullscreen) {
        this.$refs.youtube.requestFullscreen();
      } else if (this.$refs.youtube.webkitRequestFullscreen) {
        this.$refs.youtube.webkitRequestFullscreen();
      } else if (this.$refs.youtube.msRequestFullscreen) {
        this.$refs.youtube.msRequestFullscreen();
      }
    },
    getId() {
      return this.$youtube.getIdFromUrl(this.getCurrentLesson.source);
    },
    playVideo() {
      this.player.playVideo();
    },
    async calcPercent() {
      try {
        if (this.getCurrentLesson && this.getCurrentLesson.percent == 100) return;
        let totalTime = 0;
        if (this.player.player && this.player.player.getDuration) {
          totalTime = await this.player.player.getDuration();
        } else {
          return;
        }
        this.player.player.getCurrentTime().then((time) => {
          let calc = (time / totalTime) * 100;
          if (isNaN(calc)) {
            return;
          }
          calc = parseInt(calc, 10);

          this.$store.commit('setCurrentLesson', {
            ...this.$store.getters.getCurrentLesson,
            percent: calc
          })
        });
      } catch(e) {
      }
    },
    playing() {
      this.playerIsStarted = true;
    },
    verifyIfCountPercent() {
      this.calcPercent();
      if (this.getCurrentLesson.mediaType === 'youtube') {
        setTimeout(() => {
          this.verifyIfCountPercent();
        }, 1000);
      }
    },
  },
  mounted() {
    this.verifyIfCountPercent();
    const self = this;
    document.addEventListener("fullscreenchange", function() {
      if (document.fullscreenElement) {
        self.enterFullscreen();
        self.isFullscreen = true;
      } else {
        self.isFullscreen = false;
      }
    });
  },
};
</script>
<style lang="scss">
.video-js {
  width: 100% !important;
  // height: 100% !important;
  z-index: 999999;
}

.vjs-error-display.vjs-modal-dialog {
  display: none;
}

.toZindex .video-js {
  z-index: 99999999999999999;
}

.hide {
  display: none !important;
}

.video-js .vjs-control-bar {
  opacity: 1 !important;
  visibility: visible;
}

.video-js .vjs-big-play-button {
  left: 45% !important;
  top: 48% !important;
}

.yt-iframe {
  width: 100%;
}
</style>
