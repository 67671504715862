<template>
  <iframe
    allow="fullscreen"
    v-if="getCurrentLesson.mediaType === 'panda' && getCurrentLesson.is_liberated"
    id="panda-player"
    ref="pandaPlayer"
    :src="source"
    :style="`${calcHeight}`"
    style="position: relative; border: 10px; width: 100%; max-width: 980px; min-height: 100%;"
  ></iframe>
</template>
<script>
// import notify from "@/services/libs/notificacao";
import { mapGetters } from "vuex";
export default {
  props: ["source", "porcentagem", "isMobile"],
  data() {
    return {
      height: 500,
      options: {
        muted: false,
        autoplay: true,
      },
      playerReady: false,
      t: "",
      panda_ended: false,
      player: null
    };
  },
  computed: {
    ...mapGetters(['getCurrentLesson']),
    calcHeight() {
      if (this.isMobile) {
        return 'min-height: 250px !important;'
      } else {
        return 'min-height: 530px !important;'
      }
    }
  },
  methods: {
    async calcPercent() {
      try {
        if (this.getCurrentLesson && this.getCurrentLesson.percent == 100) return;
        window.pandascripttag = window.pandascripttag || [];
        await window.pandascripttag.push(() => {
          const player = new PandaPlayer("panda-player");
  
          setTimeout(() => {
            var current = player.getCurrentTime();
            var total = player.getDuration();
  
            current = parseInt(current, 10);
            total = parseInt(total, 10);
  
            var calc = (current / total) * 100;
            calc = parseInt(calc, 10);

            if (calc !== NaN) {
              this.$store.commit('setCurrentLesson', {
                ...this.$store.getters.getCurrentLesson,
                percent: calc
              })
            }
  
          }, 1000);
        });
      } catch (e) {
        window.pandascripttag = [];
        document.getElementById("fkdjshfkjsdhfkjdsh").remove();
      }
    },
    verifyIfCountPercent() {
      this.calcPercent();
      if (this.getCurrentLesson.mediaType === 'panda') {
        setTimeout(() => {
          this.verifyIfCountPercent();
        }, 1000);
      } else {
        window.pandascripttag = [];
        document.getElementById("fkdjshfkjsdhfkjdsh").remove();
      }
    },
  },
  mounted() {
    try {
      if (this.getCurrentLesson.mediaType === 'panda') {
        window.pandascripttag = window.pandascripttag || [];
        window.pandascripttag.push(() => {
          this.player = new PandaPlayer("panda-player");
        });
        if (!document.getElementById("fkdjshfkjsdhfkjdsh")) {
          let pandaScript = document.createElement("script");
          pandaScript.setAttribute(
            "src",
            "https://player.pandavideo.com.br/api.js"
          );
          pandaScript.setAttribute("id", "fkdjshfkjsdhfkjdsh");
          document.head.appendChild(pandaScript);
        }
      }
    } catch (e) {
      window.pandascripttag = [];
      document.getElementById("fkdjshfkjsdhfkjdsh").remove();
      // console.log(e);
    }
    setTimeout(() => {
      this.verifyIfCountPercent();
    }, 1000);
  },
  beforeDestroy() {
    document.getElementById("fkdjshfkjsdhfkjdsh").remove();
  },
};
</script>

<style>
.toZindex .playerVimeo {
  z-index: 99999999999999999;
}
iframe {
  max-width: 980px;
  background-repeat: no-repeat;
  background-position: center center;
}

</style>
