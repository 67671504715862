<template>
  <div class="lesson_list_container">
    <div class="lesson_list_item">
      <div v-if="situationModule === 'completed' || situationModule === 'previous'" class="lesson_list_icon_container">
        <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 1L4.75 9L1 5.36364" stroke="#16171A" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div v-if="situationModule === 'blocked'" class="lesson_list_icon_container">
        <svg class="lesson_list_item_icon_blocked_module" width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.88889 7.75V4.75C3.88889 3.75544 4.26934 2.80161 4.94656 2.09835C5.62377 1.39509 6.54227 1 7.5 1C8.45773 1 9.37623 1.39509 10.0534 2.09835C10.7307 2.80161 11.1111 3.75544 11.1111 4.75V7.75M2.44444 7.75H12.5556C13.3533 7.75 14 8.42157 14 9.25V14.5C14 15.3284 13.3533 16 12.5556 16H2.44444C1.6467 16 1 15.3284 1 14.5V9.25C1 8.42157 1.6467 7.75 2.44444 7.75Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <ProgressCircular :id="`module-progress-${module.id}`" v-if="situationModule === 'current'" style="margin-right: 20px;" :progress="calcProgressModule" />
      <div @click="validateSendToEspecificModule(module)" class="lesson_list_content">
        <span class="lesson_list_title"
          :class="{
            'selected': isCurrentModule
          }"
        >
          {{ module.title }}
        </span>
        <span class="lesson_list_subtitle">
          {{ module.lessons_count }} {{ $t("player.class") }}
        </span>
        <span v-if="!module.is_liberated" class="lesson_list_font_subtitle">
          {{ $t("player.release_in") }} {{ module.liberated_at | formatDate }}
        </span>
      </div>
    </div>
    <div class="lesson_list_content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ProgressCircular from '@/components/V2/player/ProgressCircular.vue'
import notify from "@/services/libs/notificacao";
import moment from 'moment';

export default {
  data() {
    return {
      playersKindCanGetTime: ['youtube', 'vimeo', 'panda'],
      lessons_count: null,
      lessons_completed_count: null
    }
  },
  components: {
    ProgressCircular
  },
  watch:{
    getCurrentModule(object){
      this.lessons_completed_count = object.lessons_completed_count;
      this.lessons_count = object.lessons_count
    },
  },
  computed: {
    ...mapGetters([
      'getCurrentLesson',
      'getCurrentModule',
      'getCurrentModules',
      'getCurrentModuleLessons',
      'getNextModule',
      'getHasNextModule',
      'getPlayerConfig',
      'getHasNextLesson',
    ]),
    calcProgressModule() {
      return Math.round((this.module.lessons_completed_count / this.module.lessons_count) * 100)
    },
    situationModule() {
      const isPreviosModule = this.module.id < this.getCurrentModule.id;
      const moduleIsCompleted = this.module.is_liberated && (this.calcProgressModule === 100 || this.module.lessons_count == 0)
      const moduleIsCurrent = this.module.id === this.getCurrentModule.id;
      const moduleIsBlocked = !this.module.is_liberated || !this.moduleIsClickable(this.module);
      if(this.module.id == this.getCurrentModule.id && this.lessons_completed_count && this.lessons_count && this.lessons_completed_count === this.lessons_count || this.module.lessons_completed_count === this.module.lessons_count){
        return 'completed';
      } 
      if(this.module.id == this.getCurrentModule.id && this.lessons_completed_count && this.lessons_count && this.lessons_completed_count === this.lessons_count || this.module.lessons_completed_count < this.module.lessons_count){
        return 'current';
      }
      if (moduleIsBlocked) return 'blocked';
      if (isPreviosModule){
        if(this.module.lessons_completed_count === this.module.lessons_count){
          return 'completed';
        }
        else if(this.module.lessons_completed_count < this.module.lessons_count){
          return 'current';
        }
      } 
      if (moduleIsCompleted) return 'completed';
      if (moduleIsCurrent) return 'current';
      return "current";
    },
  },
  filters: {
    formatDate(val) {
      if (!val) return "";
      return moment(val).format("DD/MM/YYYY");
    }
  },
  methods: {
    ...mapActions(['goToEspecificModule']),
    validateSendToEspecificModule(module) {
      if (this.moduleIsClickable(module)) {
        this.goToEspecificModule(module);
      } else {
        notify(
          "erro",
          "Você precisa assistir todas as aulas do módulo anterior para liberar o próximo módulo."
        );
      }
    },
    moduleIsClickable(module) {
      const isTheNextModule = module.id === this.getNextModule.id;
      const isTheCurrentModule = module.id === this.getCurrentModule.id;
      const percentIsChecked = this.getCurrentLesson.percent >= this.getPlayerConfig.minimumPercentageToCompleteLesson;
      const dontHaveNextLesson = this.getHasNextLesson;
      const shouldShow = module.should_show;

      if (!this.getPlayerConfig.limitMinimumPercentageLessonIsActive) return true;
      if (module.lessons_completed_count >= module.lessons_count ) return true;
      if (module.is_completed) return true;
      if (isTheCurrentModule) return true;

      const canBeClicked = isTheNextModule && percentIsChecked && dontHaveNextLesson && shouldShow;
      if (canBeClicked) return true;

      return false;
    },
    verifyPlayersKindCanGetTime(kind) {
      if (!kind) return false;
      return this.playersKindCanGetTime.includes(kind);
    },
  },
  props: {
    module: {
      type: Object,
      default: () => {}
    },
    isCurrentModule: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss">
.lesson_list_item_icon_blocked_module {
  height: 13px;
  width: 13px;
  stroke: #333 !important;
}
.lesson_list_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.lesson_list_item {
  flex-wrap: nowrap;
  padding-bottom: 8px;
  padding-right: 20px;
  padding-left: 0px;
  display: flex;
  align-items: center;
  border-radius: 21px;
  width: 100%;
  background: var(--background-v2);
  color: #9A9A9A !important;
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
}
.lesson_list_icon_container {
  width: 30px;
  height: 30px;
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--maincolor);
  margin-right: 20px;
  margin-left: 8px;
}
.lesson_list_icon_progress {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: var(--maincolor);
  margin-right: 20px;
}

.lesson_list_content {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.lesson_list_title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: var(--fontcolor-v2)
}
.lesson_list_subtitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--fontcolor2-v2)
}
.selected {
  font-weight: 500;
}
</style>