<template>
  <div @click="validateSendToEspecificLesson(lesson)" class="lesson_list_item_container">
    <div :class="{
        'timeline': !isSearch
      }" />
    <div v-if="!lesson.is_completed && order !== getCurrentModuleLessons.length && lesson.id  === getNextLesson.id && !isSearch" :class="{
      'timeline': !isSearch
    }" />
    <div v-if="isCurrentLesson || lessonIsClickable()"
      :class="{
        'completed': lesson.is_completed,
        'lesson_list_item_icon': !isSearch,
        'playing-timeline': lesson.id  === getCurrentLesson.id
      }"
    ></div>
    <svg v-if="!lessonIsClickable() && !isCurrentLesson" class="lesson_list_item_icon_blocked" width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.88889 7.75V4.75C3.88889 3.75544 4.26934 2.80161 4.94656 2.09835C5.62377 1.39509 6.54227 1 7.5 1C8.45773 1 9.37623 1.39509 10.0534 2.09835C10.7307 2.80161 11.1111 3.75544 11.1111 4.75V7.75M2.44444 7.75H12.5556C13.3533 7.75 14 8.42157 14 9.25V14.5C14 15.3284 13.3533 16 12.5556 16H2.44444C1.6467 16 1 15.3284 1 14.5V9.25C1 8.42157 1.6467 7.75 2.44444 7.75Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <div class="lesson_list_content">
      <span class="lesson_sub_category" v-if="verifyShowSubcategory()"> {{ lesson.small_category }} </span>
      <span class="lesson_list_font_title" :class="{
        'completed-text': lesson.is_completed,
        'playing-now': lesson.id === getCurrentLesson.id,
        'pl-2': lesson.small_category
      }">
        {{ order }}. {{ lesson.title }}
      </span>
      <span v-if="!lesson.is_liberated && lesson.liberated_at" class="lesson_list_font_subtitle">
        {{ $t("player.release_in") }} {{ lesson.liberated_at | formatDate }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import notify from "@/services/libs/notificacao";
import moment from 'moment';

export default {
  props: {
    lesson: {
      type: Object,
      default: () => { }
    },
    order: {
      type: Number,
      default: 0
    },
    isSearch: {
      type: Boolean,
      default: false
    },
  },
  filters: {
    formatDate(val) {
      if (!val) return "";
      return moment(val).format("DD/MM/YYYY");
    }
  },
  computed: {
    ...mapGetters([
      "getCurrentLesson",
      "getNextLesson",
      "getPlayerConfig",
      "getCurrentModuleLessons",
      "getNextLessonForContinue",
      "getHasNextLessonForContinue",
    ]),
    isCurrentLesson() {
      return this.lesson.id === this.getCurrentLesson.id;
    },
  },
  methods: {
    ...mapActions(["goToEspecificLesson"]),
    validateSendToEspecificLesson(lesson) {
      if (this.lessonIsClickable()) {
        this.$emit('clear-search', true);
        if(!this.isCurrentLesson) this.goToEspecificLesson({especificLesson: lesson, isLessonClick: true});
      } else {
        notify(
          "erro",
          "Você precisa assistir parte da aula liberar a próxima."
        );
      }
    },
    lessonIsClickable() {
      const isTheNextLesson = this.lesson.id === this.getNextLessonForContinue.id;
      const isCurrentLesson = this.lesson.id === this.getCurrentLesson.id;
      const percent = (this.getCurrentLesson && this.getCurrentLesson.percent !== 'NaN' && !isNaN(this.getCurrentLesson.percent)) ? this.getCurrentLesson.percent : 0;
      const percentIsChecked = percent >= this.getPlayerConfig.minimumPercentageToCompleteLesson;

      if (!this.getPlayerConfig.limitMinimumPercentageLessonIsActive) return true;
      if (this.lesson.is_completed) return true;

      const canBeClicked = (isCurrentLesson || isTheNextLesson) && percentIsChecked;
      if (canBeClicked) return true;

      return false;
    },
    verifyPlayersKindCanGetTime(kind) {
      if (!kind) return false;
      return this.playersKindCanGetTime.includes(kind);
    },
    verifyShowSubcategory() {
      const prevLesson = this.getCurrentModuleLessons[this.order - 2];
      if (this.order === 1 && this.lesson.small_category){
        return true;
      } else if (
        this.order > 0 && 
        this.lesson.small_category && 
        this.lesson.small_category !== prevLesson.small_category){
        return true;
      }
      return false;
    }
  }
}
</script>

<style lang="scss">
.timeline {
  position: absolute;
  width: 1.5px;
  height: 100%;
  background-color: var(--background2-v2);
  left: 23px;
  z-index: -1;
}


.lesson_list_content {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  overflow: hidden;
  color: var(--fontcolor-v2);
  display: flex;
  flex-direction: column;
}

.lesson_list_item_container {
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  width: 100%;
  color: #9A9A9A !important;
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  position: relative;
  font-size: 16px;
  line-height: 17px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.lesson_list_item_icon {
  height: 13px;
  width: 13px;
  min-height: 13px;
  min-width: 13px;
  border-radius: 50%;
  border: 3px solid var(--bordercolor);
  background: var(--background-v2);
  margin-right: 31px;
  margin-left: 17px;
}

@media screen and (max-width: 768px) {
  .lesson_list_item_icon {
    margin-left: 17px;
    margin-right: 30px;
  }
}

.lesson_list_item_icon_blocked {
  height: 13px;
  width: 13px;
  min-height: 13px;
  min-width: 13px;
  stroke: var(--maincolor);
  margin-right: 29px;
  margin-left: 17px;
}

.completed {
  background: var(--maincolor) !important;
  border: 3px solid var(--maincolor);
}
.playing-timeline {
  border: 3px solid var(--maincolor);
}

.lesson_list_font_title {
  color: var(--fontcolor2-v2);
  font-size: 14px;

  &.completed-text {
    text-decoration: line-through;
  }
}

.lesson_list_font_subtitle {
  color: var(--maincolor);
  font-size: 8pt;
}

.playing-now {
  color: var(--maincolor) !important;
}

.blocked {
  background: var(--background-v2);
  border: 3px solid var(--background2-v2);
}
.lesson_sub_category {
  color: var(--fontcolor-v2);
  font-size: 8pt;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: 'Montserrat';
  border-radius: 6px;
  line-height: 17px;
  letter-spacing: 0.05em;
}
</style>